import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Typography, Box, Grow } from '@mui/material'

import { getCallStartTime } from '@tabeeb/modules/presentation/selectors'
import getDurationString from '../../../shared/utils/date/getDurationString'

const CallDuration = () => {
  const callStartTime = useSelector(getCallStartTime)
  const [callDuration, setCallDuration] = useState(0)

  useEffect(() => {
    setCallDuration(Math.max(Date.now() - callStartTime, 0))

    const interval = setInterval(() => {
      setCallDuration(Math.max(Date.now() - callStartTime, 0))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [callStartTime])

  return (
    <Grow in>
      <Box sx={{ pr: 1, display: 'flex', justifyContent: 'flex-start' }} title='Call duration'>
        <Typography variant='body2' color='textSecondary' component='time'>
          {callStartTime === 0 ? '00:00' : getDurationString(callDuration)}
        </Typography>
      </Box>
    </Grow>
  )
}

export default CallDuration
