import { memo } from 'react'
import { useSelector } from 'react-redux'

import { Grow, Typography } from '@material-ui/core'

import { recordingSelectors } from '@tabeeb/modules/recording'
import getDurationString from '../../../shared/utils/date/getDurationString'

import useStyles from './styles'

const RecordingTimer = () => {
  const classes = useStyles()

  const time = useSelector(recordingSelectors.getElapsedTime)
  const isRecordingActive = useSelector(recordingSelectors.getIsRecordingActive)

  return (
    <Grow in={isRecordingActive} unmountOnExit>
      <div className={classes.container} title='Recording time'>
        <div className={classes.icon} />
        <Typography variant='body2' color='textSecondary' component='time'>
          {getDurationString(time)}
        </Typography>
      </div>
    </Grow>
  )
}

export default memo(RecordingTimer)
